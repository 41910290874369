import axios from 'axios';

const API = 'promociones-admin/carpetas';

const ENDPOINTS = {
    getListaCarpetasPromociones(){
        return axios.get(`${API}/listar`)
    },
    getDetalleCarpeta(id_carpeta){
        return axios.get(`${API}/${id_carpeta}/lista-promociones`)
    },
    getDetallePromo(id_carpeta){
        return axios.get(`${API}/${id_carpeta}/promocion-detalle`)
    },
    getListaCedisDisponibles(id_promocion){
        return axios.get(`${API}/lista/${id_promocion}/cedis-disponibles`)
    },
    crearCarpetaPromociones(payload){
        return axios.post(`${API}/crear`, payload)
    },
    anadirPromoCedis(payload){
        return axios.post(`${API}/nuevo/cedis-promocion`, payload)
    },
    editarCarpetaPromociones(payload, id_carpeta){
        return axios.put(`${API}/${id_carpeta}/editar`, payload)
    },
    trasladarPromoDeCarpeta(id_promocion, id_nueva_carpeta){
        return axios.put(`${API}/${id_promocion}/nueva-carpeta/${id_nueva_carpeta}`)
    },
    cambiarEstadoCedisPromocion(id_promocion, id_cedis){
        return axios.put(`${API}/${id_promocion}/${id_cedis}/editar-estado`)
    },
    eliminarCedisDePromocion(id_promocion, id_cedis){
        return axios.delete(`${API}/${id_promocion}/${id_cedis}/eliminar`)
    },
    eliminarCarpetaPromociones(id_carpeta){
        return axios.delete(`${API}/${id_carpeta}/eliminar`)
    },
    eliminarPromocion(id_promocion){
        return axios.delete(`${API}/${id_promocion}/promocion-eliminar`)
    },
};

export default ENDPOINTS;