<template>
    <section class="d-flex">
        <div class="column-promociones">
            <div class="row mx-0 align-items-center border-bottom py-2">
                <div class="col-auto f-18 f-600 text-general px-2">
                    Grupos de Promociones
                </div>
                <el-tooltip placement="bottom" content="Crear Grupo" effect="light">
                    <div class="btn-red ml-auto mr-2" @click="crearGrupoPromocion">
                        <i class="icon-plus f-17" />
                    </div>
                </el-tooltip>
            </div>
            <div class="custom-scroll overflow-auto pt-3" style="height: calc(100vh - 143px);">
                <div v-for="data in carpetasPromos" :key="data.id" class="row mx-0 justify-center mb-2 cr-pointer" @click="verGrupoPromocion(data.id)">
                    <div class="col-11 py-2 border br-8">
                        <p class="f-16 text-general">
                            {{ data.nombre }}
                        </p>
                        <div class="w-100 d-middle justify-content-between">
                            <p class="f-18 mt-1 text-general">
                                <i class="icon-cupon-porcentaje f-15" />
                                {{ data.cantidad_promociones }} promociones
                            </p>
                            <p v-if="data.estado == 0" class="f-15 f-400 text-gris2 px-1">
                                Inactiva
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="carpetasPromos.lenght == 0 " class="row mx-0 mt-3 justify-center mt-4">
                    <div class="col-11 text-center text-general">
                        Aun no se han creado grupos <br /> de promociones
                    </div>
                    <div class="col-11 mt-3">
                        <div class="btn-general" @click="crearGrupoPromocion">
                            Crear grupo de promociones
                        </div>
                    </div>
                </div>
            </div>
            <!-- Partials -->
            <modal ref="modalCrearGrupoPromociones" titulo="Crear Grupo de promociones" no-aceptar adicional="Guardar" @adicional="crearCarpetaPromos">
                <div class="row mx-0 justify-center">
                    <div class="col-10">
                        <small class="pl-3">
                            Nombre del grupo
                        </small>
                        <el-input v-model="nombre" class="w-100" />
                    </div>
                </div>
            </modal>
        </div>
    </section>
</template>

<script>
import Carpetas from '~/services/promociones/promociones_carpetas_admin'
export default {
    data(){
        return {
            nombre: '',
            carpetasPromos: [],
        }
    },
    mounted(){
        this.listarCarpetasPromociones()
    },
    methods: {
        crearGrupoPromocion(){
            this.$refs.modalCrearGrupoPromociones.toggle();
            this.nombre = ''
        },
        verGrupoPromocion(item){
            this.$router.push({name:'admin.promociones.grupos.ver',params:{ idGrupo:item }})
        },
        async listarCarpetasPromociones(){
            try {
                const { data } = await Carpetas.getListaCarpetasPromociones();
                this.carpetasPromos = data.listaCarpetas;
            } catch (error){
                this.error_catch(error)
            }
        },
        async crearCarpetaPromos(){
            try {
                const payload = {
                    nombreCarpeta: this.nombre,
                }
                const { data } = await Carpetas.crearCarpetaPromociones(payload);
                this.notificacion('Éxito', data.mensaje, 'success')
                this.listarCarpetasPromociones()
                this.$refs.modalCrearGrupoPromociones.toggle();

            } catch (error){
                this.error_catch(error)
            }
        },
        async editarCarpetaPromo(){
            try {
                const payload = {
                    nombreCarpeta: this.nombre,
                    estado: this.estado
                }
                const { data } = await Carpetas.editarCarpetaPromociones(payload, this.id_carpeta)
                this.listarCarpetasPromociones()
                this.notificacion('Éxito', data.mensaje, 'success')
            } catch (error){
                this.error_catch(error)
            }
        },
        async eliminarCarpetaPromo(){
            try {
                const{ data } = await Carpetas.eliminarCarpetaPromociones(this.id_carpeta)
                this.listarCarpetasPromociones()
                this.notificacion('Éxito', data.mensaje, 'success')
            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.column-promociones{
    width: 380px;
    background-color: #FFFFFF;
    border-radius: 8px 0px 0px 0px;
}
</style>
